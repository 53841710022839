<template>
  <CommonPage id="home" classs="home">
    <section class="home-content pc_padding">
      <div class="banner">
        <div class="banner-title">
          {{ domainConfig['IAmTitle'] }}
        </div>
        <div class="banner-txt">
          Get your free daily tarot card readings and personalized insights at
          {{ domainConfig['IAmTitle'] }}.
        </div>
        <div class="card-select-box">
          <carousel-3d :count="slides.length" :space="100" :perspective="0"
            :onMainSlideClick="selectACard">
            <slide v-for="(slide, i) in slides" :key="i" :index="i" style="margin: 0 auto;">
              <template slot-scope="{ index, isCurrent }">
                <img :data-index="index" :class="{ current: isCurrent }" :src="slide.src">
              </template>
            </slide>
          </carousel-3d>
        </div>
        <img src="@/assets/pc-banner.png" alt="">
        <div class="select-btn" @click="selectACard()">
          Select a card
        </div>
      </div>

      <div class="home-intro-row">
        <AdComponent ref="ads-home-1" class="m-b-40" :ads="adsensConfig.home_1"
          :showDebug="showDebug" />
        <div class="home-intro-title">
          What's {{ domainConfig['IAmTitle'] }}?
        </div>
        <div class="home-intro-content">
          {{ domainConfig['IAmTitle'] }} is a practice where individuals draw a single tarot card
          each day to gain
          insight and guidance. It helps people reflect on their day, set intentions, and connect
          with their intuition. Many use it for daily mindfulness and clarity.
        </div>

        <div class="home-intro-title">
          How to use this {{ domainConfig['IAmTitle'] }}?
        </div>
        <div class="home-intro-content">
          To use {{ domainConfig['IAmTitle'] }}, follow these simple steps:
          <br><br>
          1. Set Your Intention: Take a moment to focus on what you want guidance on for the day.
          <br><br>
          2. Draw a Card: When you feel ready, draw a single card from the deck.
          <br><br>
          3. Reflect on the Card: Look at the card and consider its imagery and meaning. Think about
          how it might relate to your day ahead.
          <br><br>
          4. Journal Your Thoughts: Write down your reflections and any insights you gain from the
          card.
          <br><br>
          5. Apply the Guidance: Use the card's message to guide your actions and decisions
          throughout the day.
        </div>

        <AdComponent ref="ads-home-2" class="m-b-40" :ads="adsensConfig.home_2"
          :showDebug="showDebug" />

        <div class="home-intro-title">
          Recommend Reading
        </div>
        <div class="home-intro-content last">
          <div class="time-page-card-row">
            <div v-for="(data,i) in card.slice(0, 2)" :key="i" class="time-page-card-item"
              @click="goto(data.routePath)">
              <div class="time-page-card-image">
                <img :src="data.img" :alt="data.title">
              </div>
              <div class="time-page-card-content">
                <div class="time-page-card-title">
                  {{ data.title }}
                </div>
                <div class="time-page-card-desc">
                  {{ data.desc }}
                </div>
              </div>
              <div class="time-page-card-btn">
                Read more
              </div>
            </div>
            <AdComponent ref="ads-home-3" class="m-b-40 ad-width-100" :ads="adsensConfig.home_3"
              :showDebug="showDebug" />
            <div v-for="(data,i) in card.slice(2, 4)" :key="i" class="time-page-card-item"
              @click="goto(data.routename)">
              <div class="time-page-card-image">
                <img :src="data.img" :alt="data.title">
              </div>
              <div class="time-page-card-content">
                <div class="time-page-card-title">
                  {{ data.title }}
                </div>
                <div class="time-page-card-desc">
                  {{ data.desc }}
                </div>
              </div>
              <div class="time-page-card-btn">
                Read more
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/pc_common.vue';
import Carousel3d from "@/components/Carousel3d.vue";
import Slide from "@/components/Slide.vue";
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    CommonPage,
    Carousel3d,
    Slide,
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      card: [
        {
          img: require('@/assets/blog1-1.png'),
          title: 'The History and Origins of Tarot Cards',
          desc: "The history of tarot cards is filled with mystery and debate. Although tarot is now closely associated with mysticism and divination, it didn’t begin as a tool for fortune-telling but as a card game. By tracing the history of tarot, we can see how it evolved from an entertainment tool into the spiritual instrument widely recognized today.",
          routename: 'blog1',
          routePath: '/blogs/the-history-and-origins-of-tarot-cards'
        },
        {
          img: require('@/assets/blog2-1.png'),
          title: "Interpreting Tarot—The Deeper Meanings of the Major and Minor Arcana",
          desc: "Tarot interpretation goes beyond simple fortune-telling, incorporating symbolism, culture, and psychology. The tarot deck is divided into two parts: the Major Arcana and the Minor Arcana, each with its own unique significance and depth.",
          routename: 'blog2',
          routePath: '/blogs/interpreting-tarot'
        },
        {
          img: require('@/assets/blog3-1.png'),
          title: 'Tarot Reading Techniques—How to Perform Accurate Tarot Interpretations',
          desc: 'Tarot reading goes beyond just flipping a few cards, observing patterns, and symbols; it involves understanding the deeper symbolism of the cards and connecting spiritually with the querent. A skilled tarot reader not only needs to be familiar with the symbolism of each card but also needs to develop a keen intuition. Below, we will explore techniques from basic skills to more advanced methods to enhance the accuracy of tarot readings.',
          routename: 'blog3',
          routePath: '/blogs/tarot-reading-techniques'
        },
        {
          img: require('@/assets/blog4-1.png'),
          title: 'The Integration of Tarot and Astrology: Exploring the Resonance Between Star Signs and Tarot Cards',
          desc: `The integration of tarot and astrology offers a captivating exploration of how these two ancient divination systems can enhance and complement each other. Both tarot and astrology have served as tools for insight and self-discovery for centuries. Although they each employ distinct methodologies, their combination can provide a richer and more nuanced understanding of one's life journey. This article delves into how tarot and astrology intersect, their shared symbols, and how their integration can deepen spiritual and psychological insights.`,
          routename: 'blog4',
          routePath: '/blogs/the-integration-of-tarot-and-astrolog'
        },
      ],
      slides: [
        {
          title: 'Slide 1',
          src: require('@/assets/tarot-card.png')
        },
        {
          title: 'Slide 2',
          src: require('@/assets/tarot-card.png')
        },
        {
          title: 'Slide 3',
          src: require('@/assets/tarot-card.png')
        },
        {
          title: 'Slide 4',
          src: require('@/assets/tarot-card.png')
        },
        {
          title: 'Slide 5',
          src: require('@/assets/tarot-card.png')
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",
      "domainConfig"
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
    this.watchForIframeInsertion()

  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds(); // 监听广告元素进入可视区域
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },

    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds(); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');
    },
    //客户端监听
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.setupIframeTracking(); // 在检测到广告 iframe 插入后，调用 setupIframeTracking
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    setupIframeTracking () {
      // let _this = this;
      console.log('ready to track iframe');
      const iframes = document.querySelectorAll('iframe');
      const tracker = new this.IframeTracker(this);
      iframes.forEach((iframe) => {
        tracker.addIframe(iframe, (iframeElement) => {
          console.log('Iframe 点击触发了!', iframeElement);
          const adContainer = iframeElement.closest('ins.adsbygoogle');

          if (adContainer) {
            // 获取父级 ins 元素的边界矩形
            const insRect = adContainer.getBoundingClientRect();

            // 计算父级 ins 元素相对于页面左上角的位置和高度
            const insX = insRect.left;
            const insY = insRect.top;
            const insHeight = insRect.height;

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            let transformData = {
              insX: insX,
              insY: insY,
              insHeight: insHeight,
              windowWidth: windowWidth,
              windowHeight: windowHeight,
            }
            this.$adClick(JSON.stringify(transformData))
            tracker.trackEvent('ad_iframe_click', 'click', {
              'data-ad-slot': adContainer.dataset.adSlot,
            });
          }
        });
      });
    },
    IframeTracker: class {
      constructor(vues, resolution = 200) {
        this.vues = vues; // 保存 Vue 实例
        this.resolution = resolution;
        this.iframes = [];
        this.interval = null;
        this.activeIframe = null; // 存储最近被点击的iframe
        this.setupVisibilityTracking();
      }

      addIframe (element, callback) {
        const adContainer = element.closest('ins.adsbygoogle');
        const iframe = {
          element: element,
          callback: callback,
          hasTracked: false,
          adSlot: adContainer ? adContainer.dataset.adSlot : null,
        };
        this.iframes.push(iframe);

        if (!this.interval) {
          this.interval = setInterval(() => this.checkClick(), this.resolution);
        }
      }

      checkClick () {
        const activeElement = document.activeElement;
        if (activeElement) {
          this.iframes.forEach((iframe) => {
            if (activeElement === iframe.element && !iframe.hasTracked) {
              iframe.callback(iframe.element);
              iframe.hasTracked = true;
              this.activeIframe = iframe; // 更新最近被点击的iframe
            }
          });
        }
      }

      setupVisibilityTracking () {
        const hiddenProperty =
          'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null;
        const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

        const onVisibilityChange = () => {
          if (!document[hiddenProperty]) {
            console.log('页面激活状态,返回页面?');
            this.activeIframe = null; // 重置最近被点击的iframe记录
          } else {
            console.log('页面非激活状态');

            if (this.activeIframe) {
              // 上报逻辑
              this.trackEvent('ad_iframe_click_leave', 'click', {
                'data-ad-slot': this.activeIframe.adSlot,
              });
            }
          }
        };

        this.iframeVisibilityTracker = onVisibilityChange;
        document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker);
      }

      trackEvent (eventName, eventValue, dataAttributes) {
        // 事件追踪逻辑
        console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

        this.vues.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    observeAds () {

      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;  // 如果是 Vue 实例，获取它的 $el
        if (adElement) {
          observer.observe(adElement); // 开始监听广告元素是否进入可视区域
          console.log('Observing ad element:', adElement);
        }
      });
    },

    loadAdWithDelay (adElement) {
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },

    selectACard () {
      this.goto('/meaning')
    },
    goto (path) {
      // this.$router.push({ name: name });
      this.customPush(path)
    },
  }
}
</script>
