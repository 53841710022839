<template>
  <section ref="homme" :class="`home detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar :title="domainConfig['IAmTitle']" :logo="domainConfig['icon']"
      :deviceType="deviceType" />

    <div class="time-page-card-container pading-l-r p-t-30">
      <div class="time-page-card-row">
        <div v-for="(data,i) in card" :key="i" class="time-page-card-item"
          @click="goto(data.routePath)">
          <div class="time-page-card-image">
            <img :src="data.img" :alt="data.title">
          </div>
          <div class="time-page-card-content">
            <div class="time-page-card-title">
              {{ data.title }}
            </div>
            <div class="time-page-card-desc">
              {{ data.desc }}
            </div>
          </div>
          <div class="time-page-card-btn">
            Read more
          </div>
        </div>
      </div>
    </div>

    <FootBar :deviceType="deviceType" />
  </section>
</template>

<script>
import common from '@/mixin/common.js';
import HeadBar from '@/pages/components/head.vue';
import FootBar from '@/pages/components/footer.vue';

import { mapGetters } from 'vuex';
import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    HeadBar,
    FootBar
  },
  mixins: [common],
  data () {
    return {
      card: [
        {
          img: require('@/assets/blog1-1.png'),
          title: 'The History and Origins of Tarot Cards',
          desc: "The history of tarot cards is filled with mystery and debate. Although tarot is now closely associated with mysticism and divination, it didn’t begin as a tool for fortune-telling but as a card game. By tracing the history of tarot, we can see how it evolved from an entertainment tool into the spiritual instrument widely recognized today.",
          routename: 'blog1',
          routePath: '/blogs/the-history-and-origins-of-tarot-cards'
        },
        {
          img: require('@/assets/blog2-1.png'),
          title: "Interpreting Tarot—The Deeper Meanings of the Major and Minor Arcana",
          desc: "Tarot interpretation goes beyond simple fortune-telling, incorporating symbolism, culture, and psychology. The tarot deck is divided into two parts: the Major Arcana and the Minor Arcana, each with its own unique significance and depth.",
          routename: 'blog2',
          routePath: '/blogs/interpreting-tarot'
        },
        {
          img: require('@/assets/blog3-1.png'),
          title: 'Tarot Reading Techniques—How to Perform Accurate Tarot Interpretations',
          desc: 'Tarot reading goes beyond just flipping a few cards, observing patterns, and symbols; it involves understanding the deeper symbolism of the cards and connecting spiritually with the querent. A skilled tarot reader not only needs to be familiar with the symbolism of each card but also needs to develop a keen intuition. Below, we will explore techniques from basic skills to more advanced methods to enhance the accuracy of tarot readings.',
          routename: 'blog3',
          routePath: '/blogs/tarot-reading-techniques'
        },
        {
          img: require('@/assets/blog4-1.png'),
          title: 'The Integration of Tarot and Astrology: Exploring the Resonance Between Star Signs and Tarot Cards',
          desc: `The integration of tarot and astrology offers a captivating exploration of how these two ancient divination systems can enhance and complement each other. Both tarot and astrology have served as tools for insight and self-discovery for centuries. Although they each employ distinct methodologies, their combination can provide a richer and more nuanced understanding of one's life journey. This article delves into how tarot and astrology intersect, their shared symbols, and how their integration can deepen spiritual and psychological insights.`,
          routename: 'blog4',
          routePath: '/blogs/the-integration-of-tarot-and-astrolog'
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "domainConfig"

    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    goto (path) {
      // this.$router.push({ name: name });
      this.customPush(path)
    }
  },
}
</script>
