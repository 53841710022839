<template>
  <CommonPage id="meaning" classs="meaning">
    <section class="meaning-content pc_padding">
      <div class="banner">
        <div class="banner-title">
          Tarot Card Meanings
        </div>
        <div class="selected-card">
          <img ref="cardImgRef" :src="imgSrc" alt="" class="rotate-in">
        </div>
        <img src="@/assets/pc-banner.png" alt="">
        <div class="select-btn" @click="refresh">
          Select a card again
        </div>
      </div>

      <AdComponent ref="ads-content-1" class="m-b-40" :ads="adsensConfig.content_1"
        :showDebug="showDebug" />

      <div class="meaning-card">
        <div class="card-title">
          {{ selectedCardInfo.name }}
        </div>
        <div class="description-items">
          <div class="description-item">
            <div class="description-item-title">
              Love
            </div>
            <div class="description-item-content">
              {{ selectedCardInfo.love }}
            </div>
          </div>
          <AdComponent ref="ads-content-2" class="m-b-40" :ads="adsensConfig.content_2"
            :showDebug="showDebug" />
          <div class="description-item">
            <div class="description-item-title">
              Career
            </div>
            <div class="description-item-content">
              {{ selectedCardInfo.career }}
            </div>
          </div>
          <div class="description-item">
            <div class="description-item-title">
              Health
            </div>
            <div class="description-item-content">
              {{ selectedCardInfo.health }}
            </div>
          </div>
        </div>
      </div>

      <div class="home-intro-row">
        <!-- <AdComponent ref="ads-answer-2" class="m-b-40 " :ads="adsensConfig.answer_2"
          :showDebug="showDebug" /> -->

        <div class="home-intro-title">
          Recommend Reading
        </div>
        <div class="home-intro-content last">
          <div class="time-page-card-row">
            <div v-for="(data,i) in card" :key="i" class="time-page-card-item"
              @click="goto(data.routePath)">
              <div class="time-page-card-image">
                <img :src="data.img" :alt="data.title">
              </div>
              <div class="time-page-card-content">
                <div class="time-page-card-title">
                  {{ data.title }}
                </div>
                <div class="time-page-card-desc">
                  {{ data.desc }}
                </div>
              </div>
              <div class="time-page-card-btn">
                Read more
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/pc_common.vue';
import AdComponent from '@/pages/components/AdComponent.vue'
import tarotConfig from '@/config/tarotConfig.json'

import '@/css/home.scss';

function convertToKebabCase (str) {
  return str
    .toLowerCase()               // 将字符串转换为小写
    .trim()                      // 去除前后的空格
    .replace(/\s+/g, '-')       // 用连字符替换空格
}

export default {
  name: 'HomeMain',
  components: {
    CommonPage,
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      card: [
        {
          img: require('@/assets/blog1-1.png'),
          title: 'The History and Origins of Tarot Cards',
          desc: "The history of tarot cards is filled with mystery and debate. Although tarot is now closely associated with mysticism and divination, it didn’t begin as a tool for fortune-telling but as a card game. By tracing the history of tarot, we can see how it evolved from an entertainment tool into the spiritual instrument widely recognized today.",
          routename: 'blog1',
          routePath: '/blogs/the-history-and-origins-of-tarot-cards'
        },
        {
          img: require('@/assets/blog2-1.png'),
          title: "Interpreting Tarot—The Deeper Meanings of the Major and Minor Arcana",
          desc: "Tarot interpretation goes beyond simple fortune-telling, incorporating symbolism, culture, and psychology. The tarot deck is divided into two parts: the Major Arcana and the Minor Arcana, each with its own unique significance and depth.",
          routename: 'blog2',
          routePath: '/blogs/interpreting-tarot'
        },
        {
          img: require('@/assets/blog3-1.png'),
          title: 'Tarot Reading Techniques—How to Perform Accurate Tarot Interpretations',
          desc: 'Tarot reading goes beyond just flipping a few cards, observing patterns, and symbols; it involves understanding the deeper symbolism of the cards and connecting spiritually with the querent. A skilled tarot reader not only needs to be familiar with the symbolism of each card but also needs to develop a keen intuition. Below, we will explore techniques from basic skills to more advanced methods to enhance the accuracy of tarot readings.',
          routename: 'blog3',
          routePath: '/blogs/tarot-reading-techniques'
        },
        {
          img: require('@/assets/blog4-1.png'),
          title: 'The Integration of Tarot and Astrology: Exploring the Resonance Between Star Signs and Tarot Cards',
          desc: `The integration of tarot and astrology offers a captivating exploration of how these two ancient divination systems can enhance and complement each other. Both tarot and astrology have served as tools for insight and self-discovery for centuries. Although they each employ distinct methodologies, their combination can provide a richer and more nuanced understanding of one's life journey. This article delves into how tarot and astrology intersect, their shared symbols, and how their integration can deepen spiritual and psychological insights.`,
          routename: 'blog4',
          routePath: '/blogs/the-integration-of-tarot-and-astrolog'
        },
      ],
      selectedIndex: 0, // 抽中的卡片索引
      clickCount: 0, // 点击次数
      maxClicks: 50, // 最大点击次数
      showToast: false, // 是否显示提示框
      imgSrc: "" // 图片路径
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",
      "domainConfig"
    ]),
    selectedCardInfo () {
      return tarotConfig[this.selectedIndex]
    },
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    this.initCode()
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
    this.watchForIframeInsertion()

  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    // #region 广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    //客户端监听
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.setupIframeTracking(); // 在检测到广告 iframe 插入后，调用 setupIframeTracking
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    setupIframeTracking () {
      // let _this = this;
      console.log('ready to track iframe');
      const iframes = document.querySelectorAll('iframe');
      const tracker = new this.IframeTracker(this);
      iframes.forEach((iframe) => {
        tracker.addIframe(iframe, (iframeElement) => {
          console.log('Iframe 点击触发了!', iframeElement);
          const adContainer = iframeElement.closest('ins.adsbygoogle');

          if (adContainer) {
            // 获取父级 ins 元素的边界矩形
            const insRect = adContainer.getBoundingClientRect();

            // 计算父级 ins 元素相对于页面左上角的位置和高度
            const insX = insRect.left;
            const insY = insRect.top;
            const insHeight = insRect.height;

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            let transformData = {
              insX: insX,
              insY: insY,
              insHeight: insHeight,
              windowWidth: windowWidth,
              windowHeight: windowHeight,
            }
            this.$adClick(JSON.stringify(transformData))
            tracker.trackEvent('ad_iframe_click', 'click', {
              'data-ad-slot': adContainer.dataset.adSlot,
            });
          }
        });
      });
    },
    IframeTracker: class {
      constructor(vues, resolution = 200) {
        this.vues = vues; // 保存 Vue 实例
        this.resolution = resolution;
        this.iframes = [];
        this.interval = null;
        this.activeIframe = null; // 存储最近被点击的iframe
        this.setupVisibilityTracking();
      }

      addIframe (element, callback) {
        const adContainer = element.closest('ins.adsbygoogle');
        const iframe = {
          element: element,
          callback: callback,
          hasTracked: false,
          adSlot: adContainer ? adContainer.dataset.adSlot : null,
        };
        this.iframes.push(iframe);

        if (!this.interval) {
          this.interval = setInterval(() => this.checkClick(), this.resolution);
        }
      }

      checkClick () {
        const activeElement = document.activeElement;
        if (activeElement) {
          this.iframes.forEach((iframe) => {
            if (activeElement === iframe.element && !iframe.hasTracked) {
              iframe.callback(iframe.element);
              iframe.hasTracked = true;
              this.activeIframe = iframe; // 更新最近被点击的iframe
            }
          });
        }
      }

      setupVisibilityTracking () {
        const hiddenProperty =
          'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null;
        const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');

        const onVisibilityChange = () => {
          if (!document[hiddenProperty]) {
            console.log('页面激活状态,返回页面?');
            this.activeIframe = null; // 重置最近被点击的iframe记录
          } else {
            console.log('页面非激活状态');

            if (this.activeIframe) {
              // 上报逻辑
              this.trackEvent('ad_iframe_click_leave', 'click', {
                'data-ad-slot': this.activeIframe.adSlot,
              });
            }
          }
        };

        this.iframeVisibilityTracker = onVisibilityChange;
        document.addEventListener(visibilityChangeEvent, this.iframeVisibilityTracker);
      }

      trackEvent (eventName, eventValue, dataAttributes) {
        // 事件追踪逻辑
        console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

        this.vues.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
      }, 500);
    },
    async displayAd () {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    // #endregion 广告相关end

    /** 随机抽一张卡 */
    initCode () {
      this.selectedIndex = Math.floor(Math.random() * 62)
      this.$refs.cardImgRef.classList.remove('rotate-in')
      this.imgSrc = require(`@/assets/cards/${convertToKebabCase(this.selectedCardInfo.name)}.webp`)
      this.$refs.cardImgRef.offsetWidth // 强制重绘
      this.$refs.cardImgRef.classList.add('rotate-in')
    },
    /** 重新抽卡 */
    refresh () {
      this.initCode();
    },
    goto (path) {
      // this.$router.push({ name: name });
      this.customPush(path)
    },
  }
}
</script>
