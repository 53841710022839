<template>
  <component :is="commonComponent" id="blog" classs="blog ">
    <section id="blog-content" class="pc_padding">
      <h1 class="m-b-30 head-1">
        Interpreting Tarot—The Deeper Meanings of the Major and Minor Arcana
      </h1>

      <div class="autor m-b-30">
        <div>Author: Momo </div>
        <div>Publish: 2025/1/3 </div>
      </div>
      <div class="slish m-b-20" />

      <!-- <AdComponent ref="ads-blog-1" class="m-b-40 " :ads="adsensConfig.blog_1"
        :showDebug="showDebug" /> -->

      <p>
        Tarot interpretation goes beyond simple fortune-telling, incorporating symbolism, culture,
        and psychology. The tarot deck is divided into two parts: the Major Arcana and the Minor
        Arcana, each with its own unique significance and depth.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Interpretation of the Major Arcana
      </h2>

      <p>
        The Major Arcana consists of 22 cards, often seen as the core of the tarot. Each card
        symbolizes a significant turning point and spiritual meaning in life’s journey. From "The
        Fool" to "The World," the Major Arcana maps a person’s spiritual journey from ignorance to
        wisdom, from chaos to harmony.
      </p>

      <!-- <AdComponent ref="ads-blog-2" class="m-b-40 " :ads="adsensConfig.blog_2"
        :showDebug="showDebug" /> -->

      <p class="m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/blog2-1.png">
      </p>

      <p>
        <span class="bold">The Fool</span>: Represents boundless potential and adventurous spirit.
        It symbolizes new beginnings, urging us to take the first step toward our dreams.
      </p>

      <p>
        <span class="bold">The Magician</span>: Symbolizes the manifestation of personal power and
        will. It reminds us to use our resources wisely and turn ideas into action.
      </p>

      <p>
        <span class="bold">The High Priestess</span>: Represents inner wisdom and intuition,
        symbolizing the power of the subconscious and encouraging us to listen to our inner voice.
      </p>

      <p>
        <span class="bold">The Empress</span>: Symbolizes fertility and creativity. She is closely
        connected to nature and reminds us to live harmoniously and care for others.
      </p>
      <p>
        <span class="bold">The Emperor</span>: Represents structure and authority, reminding us to
        maintain control and follow the rules.
      </p>
      <p>
        <span class="bold">The Hierophant</span>: Symbolizes tradition and social norms, urging us
        to respect established wisdom but also to question the status quo when necessary.
      </p>
      <p>
        <span class="bold">The Lovers</span>: Represents the power of love and choice, reminding us
        that harmony in relationships is built on mutual understanding and compromise.
      </p>
      <p>
        <span class="bold">The Chariot</span>: Symbolizes the triumph of willpower, reminding us to
        move steadfastly toward our goals in the face of challenges.
      </p>
      <p>
        <span class="bold">Strength</span>: Reminds us that true strength lies in inner calm and
        patience, not external force.
      </p>
      <p>
        <span class="bold">The Hermit</span>: Symbolizes inner exploration and the pursuit of
        wisdom, reminding us that solitude is sometimes necessary for growth.
      </p>
      <p>
        <span class="bold">The Wheel of Fortune</span>: Represents life's cycles and the
        unpredictability of change, reminding us that both good and bad times are part of life.
      </p>
      <p>
        <span class="bold">Justice</span>: Symbolizes fairness and responsibility, urging us to
        consider the consequences of our decisions and act justly.
      </p>
      <p>
        <span class="bold">The Hanged Man</span>: Represents sacrifice and shifting perspectives,
        encouraging us to view situations from different angles and let go when necessary.
      </p>
      <p>
        <span class="bold">Death</span>: Despite its ominous name, it symbolizes endings and new
        beginnings, reminding us that every ending brings a new start.
      </p>
      <p>
        <span class="bold">Temperance</span>: Represents balance and harmony, reminding us to find
        moderation in life and avoid extremes.
      </p>
      <p>
        <span class="bold">The Devil</span>: Symbolizes material temptation and bondage, reminding
        us to recognize our desires and avoid being controlled by them.
      </p>
      <p>
        <span class="bold">The Tower</span>: Represents sudden change and destruction, reminding us
        that old structures may need to fall apart for new growth to occur.
      </p>
      <p>
        <span class="bold">The Star</span>: Symbolizes hope and spiritual guidance, encouraging us
        to keep faith during difficult times and believe in a brighter future.
      </p>
      <p>
        <span class="bold">The Moon</span>: Represents the power of the subconscious and illusion,
        reminding us to be wary of fears and deceptions.
      </p>
      <p>
        <span class="bold">The Sun</span>: Symbolizes success and happiness, reminding us that with
        a positive attitude and confidence, we can achieve our goals.
      </p>
      <p>
        <span class="bold">Judgment</span>: Represents self-reflection and rebirth, encouraging us
        to review our past, learn from it, and prepare for transformation.
      </p>
      <p>
        <span class="bold">The World</span>: Symbolizes completion and achievement, marking the end
        of one phase and the beginning of a new journey.
      </p>
      <h2 class="head-2 m-t-60 m-b-30">
        Interpretation of the Minor Arcana (continued)
      </h2>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/blog2-2.png">
      </p>

      <p>
        <span class="bold">Wands</span>: Represent the element of fire, symbolizing action,
        creativity, and passion. Wands are often associated with career, goals, and ambition.
      </p>
      <p>
        <span class="bold">Cups</span>: Represent the element of water, symbolizing emotions,
        relationships, and intuition. Cups reflect personal emotional experiences, love, and
        spiritual growth.
      </p>
      <p>
        <span class="bold">Swords</span>: Represent the element of air, symbolizing thoughts,
        communication, and decisions. Swords deal with mental activities, conflicts, and
        decision-making processes.
      </p>
      <p>
        <span class="bold">Pentacles</span>: Represent the element of earth, symbolizing the
        material world, wealth, and health. Pentacles are related to practical aspects of life, such
        as financial stability and physical well-being.
      </p>
      <p>
        The numbered cards in the Minor Arcana (1 through 10) represent specific events and
        experiences in daily life, while the Court Cards (Page, Knight, Queen, and King) symbolize
        different personality traits and roles.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Application and Interpretation of Spreads
      </h2>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/blog2-3.png">
      </p>

      <p>
        Choosing the right spread is crucial for tarot interpretation. Common spreads include the
        Three-Card Spread (Past, Present, Future) and the Celtic Cross Spread.
      </p>

      <p>
        <span class="bold">Three-Card Spread: <br></span>
        The first card represents past influences, the second card indicates the present situation,
        and the third card reveals potential future outcomes. This simple spread is ideal for
        beginners, as it provides a straightforward insight into the core of the issue.
      </p>
      <p>
        <span class="bold">Celtic Cross Spread: <br></span>
        A more complex layout involving 10 cards, used to explore an individual’s internal and
        external world. This spread provides detailed analysis of deeper issues and potential
        solutions, suitable for experienced readers.
      </p>
      <p>
        By analyzing the position and relationship of each card in the spread, the reader can gain a
        comprehensive understanding of an individual’s life state, underlying challenges, and future
        possibilities.
      </p>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/common.scss';
import '@/css/blog_base.scss';

export default {
  name: 'Blog1',
  components: {
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: "Interpreting Tarot—The Deeper Meanings of the Major and Minor Arcana",
      meta: [
        {
          name: 'description',
          content: `The Major Arcana consists of 22 cards, often seen as the core of the tarot. Each card symbolizes a significant turning point and spiritual meaning in life’s journey. From "The Fool" to "The World," the Major Arcana maps a person’s spiritual journey from ignorance to wisdom, from chaos to harmony.`
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",

    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
      }, 500);
    },
    async displayAd () {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end
  },
};
</script>
