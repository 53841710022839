<template>
  <component :is="commonComponent" id="blog" classs="blog ">
    <section id="blog-content" class="pc_padding">
      <h1 class="m-b-30 head-1">
        The History and Origins of Tarot Cards
      </h1>

      <div class="autor m-b-30">
        <div>Author: Momo </div>
        <div>Publish: 2025/1/3 </div>
      </div>
      <div class="slish m-b-20" />

      <!-- <AdComponent ref="ads-blog-1" class="m-b-40 " :ads="adsensConfig.blog_1"
        :showDebug="showDebug" /> -->

      <p>
        The history of tarot cards is filled with mystery and debate. Although tarot is now closely
        associated with mysticism and divination, it didn’t begin as a tool for fortune-telling but
        as a card game. By tracing the history of tarot, we can see how it evolved from an
        entertainment tool into the spiritual instrument widely recognized today.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        The Birth of Early Tarot Cards
      </h2>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/blog1-1.png">
      </p>

      <!-- <AdComponent ref="ads-blog-2" class="m-b-40 " :ads="adsensConfig.blog_2"
        :showDebug="showDebug" /> -->

      <p>
        The origins of tarot can be traced back to 14th-century Europe, with the earliest tarot
        decks appearing in Italy. At that time, tarot was not linked to divination but was enjoyed
        as a card game by nobles and the upper class. This game, known as “tarocchi” or “tarock,”
        resembled modern-day card games. One of the earliest tarot decks, the Visconti-Sforza Tarot,
        was custom-made for a Milanese noble family and featured intricate details, including gold
        leaf on the cards.
      </p>

      <p>
        In these early tarot games, the major and minor arcana were integral, making tarot unique
        compared to standard playing cards. The symbolic imagery on the major arcana laid the
        groundwork for later mystics to connect tarot with divination.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Tarot and Mysticism
      </h2>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/blog1-2.png">
      </p>

      <p>
        The 18th century marked a turning point for tarot, as French mystics began to associate it
        with ancient mysticism and divination practices. Figures like Cartomancer de Calrez and
        Jean-Baptiste Alliette studied the symbols and images of tarot, linking them to ancient
        Egyptian culture, Hebrew letters, and other esoteric systems.
      </p>

      <p>
        They believed that tarot was not just a game but a symbol of ancient wisdom. The major
        arcana came to represent a spiritual journey, while the minor arcana reflected daily
        challenges. This mystical interpretation laid the foundation for tarot's role as a tool for
        spiritual exploration.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Aleister Crowley and the Modernization of Tarot
      </h2>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/blog1-3.png">
      </p>

      <p>
        By the late 19th century, tarot had gained acceptance within Western occult circles, with
        figures like Aleister Crowley leading its modernization. Crowley, a renowned mystic and
        ceremonial magician, designed the Thoth Tarot deck, incorporating elements from Egyptian
        mythology, Kabbalah, astrology, and other esoteric traditions.
      </p>

      <p>
        Crowley’s deck emphasized not just visual symbolism but also delved into the psychological
        and spiritual meanings behind the cards. His work left a lasting impact on modern tarot
        interpretation, influencing future readers and designers.
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        Tarot’s Global Spread
      </h2>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/blog1-4.png">
      </p>

      <p>
        In the 20th century, tarot spread globally, especially as Western culture expanded. Tarot
        became especially popular during the New Age Movement in the United States, where it was
        promoted as a tool for self-discovery and spiritual growth.
      </p>

      <p>
        Today, tarot has a wide range of uses, from personal meditation to therapeutic applications.
        As tarot has reached different parts of the world, cultural interpretations of the cards
        have emerged, but the core idea—exploring the inner world through symbols—remains the same.
      </p>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/blog1-5.png">
      </p>

      <h2 class="head-2 m-t-60 m-b-30">
        The Future of Tarot
      </h2>

      <p class="m-b-30">
        <img class="border-radius-20" alt="img" src="@/assets/blog1-6.png">
      </p>

      <p>
        With advances in technology, tarot is also embracing the digital age. Many tarot enthusiasts
        now use apps or online platforms for readings, breaking the limitations of physical cards.
        Furthermore, the rise of artificial intelligence is opening new possibilities for
        interpreting tarot.
      </p>

      <p>
        However, no matter how tarot evolves technologically, its core function as a spiritual tool
        remains unchanged. Tarot continues to help individuals explore their inner world through
        symbolic imagery, and it will undoubtedly remain a vital part of spiritual journeys in the
        future.
      </p>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/common.scss';
import '@/css/blog_base.scss';

export default {
  name: 'Blog1',
  components: {
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'The History and Origins of Tarot Cards',
      meta: [
        {
          name: 'description',
          content: "The history of tarot cards is filled with mystery and debate. Although tarot is now closely associated with mysticism and divination, it didn’t begin as a tool for fortune-telling but as a card game. By tracing the history of tarot, we can see how it evolved from an entertainment tool into the spiritual instrument widely recognized today.",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",

    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
      }, 500);
    },
    async displayAd () {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end
  },
};
</script>
